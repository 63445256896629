<template>
  <div style="width: 100%; height: 100%">
    <!--  <div style="width: 100%; height: 60px">
          <el-date-picker
                  style="margin: 15px 10px 10px 20px; height: 40px; width: auto; float: left"
                  v-model="selectDate"
                  @change="changeDate"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :shortcuts="shortcuts"
                  :default-time="defaultTime">
          </el-date-picker>

          &lt;!&ndash;
                      <div :class="[isactive === 1?'tv-selected':'tv-normal']" @click="initCharts(1)" style="margin: 20px 10px 10px 20px; float: left">最近24小时</div>
                      <div :class="[isactive === 2?'tv-selected':'tv-normal']" @click="initCharts(2)" style="margin: 20px 10px 10px 20px; float: left">最近三天</div>
                      <div :class="[isactive === 3?'tv-selected':'tv-normal']" @click="initCharts(3)" style="margin: 20px 10px 10px 20px; float: left">最近一周</div>
                      <div :class="[isactive === 4?'tv-selected':'tv-normal']" @click="initCharts(4)" style="margin: 20px 10px 10px 20px; float: left">最近一月</div>&ndash;&gt;
      </div>-->
    <div v-loading="loading" style="width: 100%; height: calc(100% - 110px); margin-top: 15px">
      <v-chart ref="chart1" style="width: 100%; height: 100%" className="chart" :option="option" :auto-resize="true"
             />
    </div>

  </div>

</template>
<script>

import VChart from "vue-echarts";
import {ref} from "vue";
import {formatDate} from '../js/formatDate.js';
//  import { getDeviceHistoryByID, getDeviceInfo } from "../../api/index";
export default {
  name: "WXNotifyInfo",
  components: {
    VChart
  },
  setup: () => {
    const xLabel = ref([]);
    const serieName = ref("实时数据");
    const mydatas = ref([]);
    const cValue = ref([]);
    const dValue = ref([]);
    const myVisualMap_pieces = ref([])
    const device = ref({});
    const option = ref({
      title: {
        text: serieName,
        left: "20px"
      },
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          let param = params[0];
          let tt = formatDate(new Date(parseInt(param.value[0])), 'yyyy-MM-dd hh:mm:ss');
          let res = '时间 : ' + tt;
          //使用for可以将需要的数据全部加到res
          //注意下边的<br/>
          for (let i = 0; i < params.length; i++) {
            res += '<br/>' + params[i].seriesName + ' : ' + (device.value.type == 'RELAY' ? params[i].value[1] == 1 ? '打开' : '关闭' : params[i].value[1]) + device.value.unit;
          }
          return res;
        }
        // formatter: "{a} <br/>{b} : {c}"
      },
      xAxis: {
        type: 'time',   // 还有其他的type，可以去官网喵两眼哦
        //  data: xLabel,   // x轴数据
        minInterval: 3600000,
        name: '',   // x轴名称
        axisLabel: {
          formatter: function (value, index) {
            return formatDate(new Date(parseInt(value)), 'MM-dd\nhh:mm');
          }
        },
        // x轴名称样式
        nameTextStyle: {
          fontWeight: 500,
          fontSize: 14
        },
        axisLine: {             //坐标轴轴线相关设置。
          lineStyle: {
            color: '#7e7e7e',
          }
        },
      },
      yAxis: {
        type: 'value',
        //   name: '纵轴名称',   // y轴名称
        // y轴名称样式
        nameTextStyle: {
          fontWeight: 600,
          fontSize: 18
        },
        axisLine: {             //坐标轴轴线相关设置。
          lineStyle: {
            color: '#7e7e7e',
          }
        },
      },
      legend: {
        orient: "vertical",
        right: "center",
        data: ["实时数据"]
      },
      dataZoom: [     // 主要是这一部分，他是折线图的缩放功能的开启
        {
          type: 'inside',
          start: 0,
          end: 100,
          bottom: '5px',
          minValueSpan: 3600 * 1000 * 2
        }, {
          start: 0,
          end: 100,
          bottom: '5px'
        }
      ],
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          // restore: {},
          // saveAsImage: {}
        }
      },
      visualMap: {
        pieces: [{
          gt: 0.01,
          lte: 1,
          color: '#FFA708'
        }],
        outOfRange: {
          color: '#5470c6'
        }
      },
      series: [
        {
          name: "实时数据",
          type: "line",
          data: mydatas,
          symbol: "circle", //拐点的形状

          markLine: {
            symbol:['circle', 'arrow'], //箭头
            silent: true,
            lineStyle: {
              type: 'dashed'
            },
            data: [
              {
                yAxis:cValue,
                lineStyle: {
                  width: 1.656,
                  color: '#FFA708'
                },
                label: {
                  show: true,
                  color: '#FFA708',
                  fontSize: '14',
                }
              },
              {
                yAxis:dValue,
                lineStyle: {
                  width: 1.656,
                  color: '#fa6969'
                },
                label: {
                  show: true,
                  color: '#fa6969',
                  fontSize: '14',
                }
              }

            ]
          },
        }
      ]
    });

    return {option, xLabel, mydatas, device, cValue, dValue, serieName, myVisualMap_pieces};
  },
  data() {
    return {
      id: this.$route.params.id,
      belongCompanyAccount: this.$route.params.belongCompanyAccount,
      deviceGroupSN: this.$route.params.deviceGroupSN,
      deviceID: this.$route.params.deviceID,
      selectDate: [new Date(new Date().getTime() - 24 * 60 * 60 * 1000), new Date()],
      valueList: [],
      isactive: 1,
      loading: true,
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59)
      ],
      shortcuts: [
        {
          text: '最近24小时',
          value: [new Date(new Date().getTime() - 24 * 60 * 60 * 1000), new Date()]
        },
        {
          text: '最近三天',
          value: [new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000), new Date()]
        },
        {
          text: '最近一周',
          value: [new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000), new Date()]
        },
        {
          text: '最近一月',
          value: [new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000), new Date()]
        }
      ]
    }
  },
  created() {
    /* console.error(this.$route.query.token)
     try {
         if (this.token != null) {
             let userInfo = {
                 token: this.token,
             }
             localStorage.setItem("userInfo", JSON.stringify(userInfo));
             this.$store.state.userInfo = userInfo;
             this.apiUtils.addToken(userInfo);
         }else{
             let userInfo = JSON.parse(localStorage.getItem("userInfo"));
             this.token = userInfo.token;
         }
     }catch (e) {
         console.error(e)
     }*/

    window.timeChange = this.timeChange;
    window.deviceChange = this.deviceChange;
    //this.getDeviceInfo()
    this.getDeviceHistoryByID()
  },
  watch: {
    '$route'(to, from) {
      this.id = this.$route.params.id;
      this.belongCompanyAccount = this.$route.params.belongCompanyAccount;
      this.deviceGroupSN = this.$route.params.deviceGroupSN;
      this.deviceID = this.$route.params.deviceID;
      this.getDeviceHistoryByID()
    }
  },
  methods: {
    timeChange(startTime, endTime) {
      console.log("timeChange >>>>>>>>>>>>* ")
      this.selectDate[0] = new Date(startTime);
      this.selectDate[1] = new Date(endTime + 24 * 60 * 60 * 1000 - 1);
      this.getDeviceHistoryByID()
    },
    initCharts(index) {
      console.log(index)
      this.isactive = index;
      if (index == 1) {
        this.selectDate[0] = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
        this.selectDate[1] = new Date();
      } else if (index == 2) {
        this.selectDate[0] = new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000);
        this.selectDate[1] = new Date();
      } else if (index == 3) {
        this.selectDate[0] = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
        this.selectDate[1] = new Date();
      } else if (index == 4) {
        this.selectDate[0] = new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000);
        this.selectDate[1] = new Date();
      }
      this.getDeviceHistoryByID()
    },
    changeDate(val) {
      this.getDeviceHistoryByID()
    },

    getDeviceHistoryByID() {
      this.loading = true;
      this.apiUtils.syDeviceHistoryByTRID({id: this.id, belongCompanyAccount: this.belongCompanyAccount,
        deviceGroupSN: this.deviceGroupSN, deviceID: this.deviceID}).then(res => {
        if (res.code == 200) {
          this.valueList = res.data.deviceHistoryList
          this.cValue = [res.data.cvalue];
          this.dValue = [res.data.dvalue];
          let c = Number.parseFloat(res.data.cvalue);
          let d = Number.parseFloat(res.data.dvalue);
          if (res.data.thresholdType == 'greater'){
            this.option.visualMap={
              pieces: [
                {
                  gt: c,
                  lte: d,
                  color: '#FFA708'
                },
                {
                  gt: d,
                  color: '#fa6969'
                }
              ],
              outOfRange: {
                color: '#5470c6'
              }
            };
          }else if (res.data.thresholdType == 'less'){
            this.option.visualMap={
              pieces: [
                {
                  gt: d,
                  lte: c,
                  color: '#FFA708'
                },
                {
                  lte: d,
                  color: '#fa6969'
                }
              ],
              outOfRange: {
                color: '#5470c6'
              }
            };
          }

          this.device = {
            unit:res.data.unit?(" ("+res.data.unit+")"):"",

          };
          this.serieName = res.data.deviceName + (res.data.unit?(" ("+res.data.unit+")"):"")
          console.log(this.cValue + " ___ "+this.dValue)
          let labels = [];
          let datas = [{
            label: '数值',
            data: []
          }];
          if (this.valueList == null || this.valueList.length < 1) {
            labels.push(start)
            datas[0].data.push(null)
            labels.push(end)
            datas[0].data.push(null)
          } else {
            for (let valueListElement of this.valueList) {
              labels.push(valueListElement.createTime)
              // labels.push(n++)
              datas[0].data.push([valueListElement.createTime, valueListElement.value == 'ON' ? 1
                  : valueListElement.value == 'OFF' ? 0
                      : valueListElement.value])
            }
          }


          //  this.option.series[0].data = datas[0].data
          //   this.option.xAxis.data = labels
          this.xLabel = labels;
          this.mydatas = datas[0].data;
        } else {
          this.$message.error("获取历史记录失败! " + res.code);
          console.log(res);
        }

      }).finally(() => {
        this.loading = false;
      });

    },
    resizeTheChart() {
      if (this.$refs && this.$refs.chart1) {
        this.$refs.chart1.resize();
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.resizeTheChart)
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeTheChart);
  },

}
</script>

<style scoped>
.schart-box {
  display: inline-block;
  margin: 20px;
}

.schart {
  width: 600px;
  height: 400px;
}

.content-title {
  clear: both;
  font-weight: 400;
  line-height: 50px;
  margin: 10px 0;
  font-size: 22px;
  color: #1f2f3d;
}
</style>